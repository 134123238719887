import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import IdentityModal, { useIdentityContext } from "react-netlify-identity-widget"

export const PerksPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content
  const identity = useIdentityContext()
  const [dialog, setDialog] = React.useState(false)
  const user = identity && identity.user

  return (
    <div>
      <section className="pt-6 pt-md-11 bg-dark pb-md-10">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 text-center text-white">

              <h6 className="text-uppercase text-warning mb-5">
                Work made better
              </h6>

              <h2 className="display-3 mb-4">
                Member Perks
              </h2>

              <p className="font-size-lg">
                Our coworking memberships offer access to our physical space and all of the benefits and perks below.
              </p>

            </div>
          </div>
        </div>
      </section>


      <section class="py-10 py-md-12">
        <div class="container-lg">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8 text-center">


              <h6 class="text-uppercase text-primary mb-5">
                Welcome to Reno Collective
              </h6>


              <h2 class="display-4 mb-10">
                A community of people and resources to help you grow.
              </h2>

            </div>
          </div>
          <div class="row mt-n8 mt-md-n9">
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">

                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M18 14a3 3 0 110-6 3 3 0 010 6zm-9-3a4 4 0 110-8 4 4 0 010 8z" fill="#335EEA" opacity=".3"></path><path d="M17.601 15c3.407.038 6.188 1.76 6.397 5.4.009.147 0 .6-.542.6H19.6c0-2.25-.744-4.328-1.999-6zm-17.6 5.2C.388 15.426 4.26 13 8.983 13c4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H.727c-.25 0-.747-.54-.726-.8z" fill="#335EEA"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    RC Community
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Grow and learn with members from just about every industry you can think of, with countless years of experience and knowledge to share, give feedback, and answer questions.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">


                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M18.425 12.646l2.828-2.828a.5.5 0 01.707 0l.707.707a.5.5 0 010 .707l-2.828 2.829a.5.5 0 01-.707 0l-.707-.707a.5.5 0 010-.708zM3.222 17.95l9.9-9.9a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415l-9.9 9.9a1 1 0 01-1.414 0l-1.414-1.415a1 1 0 010-1.414z" fill="#335EEA" opacity=".3"></path><path d="M9.586 4.515a5 5 0 017.07 0l3.183 3.182a.5.5 0 010 .707l-2.829 2.828a.5.5 0 01-.707 0L9.586 4.515z" fill="#335EEA"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Cowork with Us
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Being "around" other people helps get things done. Join us for hosted, in-person and virtual <span class="text-primary">Cowork with Us</span> sessions.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-9">
                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M3.5 3H5v16.5a1.5 1.5 0 01-3 0v-15A1.5 1.5 0 013.5 3z" fill="#335EEA"></path><path d="M7 3h12.755a1 1 0 01.743 1.669L17.5 8l2.998 3.331A1 1 0 0119.755 13H7V3z" fill="#335EEA" opacity=".3"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Prime Location
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Find us in Midtown at the corner of Mt Rose and Plumas Streets, in the former Granny's Recording Studio. The stories these walls could tell...
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">

                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M22 15.01l.025-.01v4.586a1 1 0 01-1.707.707L18.025 18H5a3 3 0 01-3-3V6a3 3 0 013-3h14a3 3 0 013 3v9.01zM6.168 10.555C7.678 12.819 9.646 14 12 14c2.354 0 4.322-1.18 5.832-3.445a1 1 0 10-1.664-1.11C15.01 11.181 13.646 12 12 12c-1.646 0-3.01-.82-4.168-2.555a1 1 0 00-1.664 1.11z" fill="#335EEA"/></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                  Find Your Reno Network
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Connect and collaborate with fellow members via our member Portal, slack, and social events. Yes, a "cereal bar" is a thing.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">

                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M8.4 17H5c-1.657 0-3-1.843-3-3.5S3.343 10 5 10h1V8h15v3.5A7.5 7.5 0 018.4 17zm-1.535-2a7.455 7.455 0 01-.849-3H5c-.552 0-1 .948-1 1.5S4.448 15 5 15h1.865z" fill="#335EEA"></path><rect fill="#335EEA" opacity=".3" x="6" y="21" width="15" height="2" rx="1"></rect><path d="M8.116 0l1.157 1.153a2.195 2.195 0 00.242 3.307l.485.362-.977 1.31-.485-.363A3.829 3.829 0 018.116 0zM13.116 1l1.157 1.153a2.195 2.195 0 00.242 3.307l.485.362-.977 1.31-.485-.363A3.829 3.829 0 0113.116 1zM18.116 0l1.157 1.153a2.195 2.195 0 00.242 3.307l.485.362-.977 1.31-.485-.363A3.829 3.829 0 0118.116 0z" fill="#335EEA" opacity=".3"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                  Kitchen & Coffee
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Full kitchen with unlimited coffee from our local <a href="https://coffeebar.com" target="_blank">Coffeebar</a>, organic teas, and just about every coffee-making device you can think of.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">

                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><circle fill="#335EEA" opacity=".3" cx="12" cy="12" r="9"></circle><path d="M11.736 20.996a9 9 0 01-8.733-9.245c.66.063 1.853.206 1.91.67.077.64 0 1.44.545 1.68s.701-.96 1.169-.64c.467.32 1.714.629 1.714 1.195 0 .566-.234 1.447 0 1.608.234.16 1.168 1.28 1.168 1.44 0 .161.33.975.231 1.287-.08.258-.491 1.06-.89 1.424a8.742 8.742 0 002.886.581zM8.283 3.801A8.967 8.967 0 0112 3a9 9 0 018.036 4.944c.034.78.028 1.447.2 1.689.877 1.227-2.136 2.071-1.679 3.928.202.818-2.004.559-2.543 1.325-.54.767-1.857.252-2.162.104-.305-.147-1.475.344-1.373-.49.102-.835.813-.884 1.577-1.228.762-.343 1.863-1.485 1.322-1.634-2.546-.702-3.414-3.16-3.414-3.16-.153-.029-.115-1.737-.776-1.786-.661-.05-1.018.196-1.984 0s-.763-.835-.915-2.21c-.034-.308-.033-.525-.006-.68zm12.716 8.076L21 12a9 9 0 01-8.91 9c4.88-.05 8.844-4.112 8.91-9.123z" fill="#335EEA" opacity=".3"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Sustainability
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    We are always seeking a sustainable option to lessen our impact on the planet - from e-cycle days to composting and recycling.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">

                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M12 22a9 9 0 110-18 9 9 0 010 18z" fill="#335EEA" opacity=".3"></path><path d="M11.963 7.5h.085a.5.5 0 01.497.45L13 12.5l3.248 1.856a.5.5 0 01.252.434V15a.382.382 0 01-.482.368l-4.62-1.26a.5.5 0 01-.366-.52l.432-5.626a.5.5 0 01.499-.462z" fill="#335EEA"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Flexible Access
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    East Coast hours? Nightowl? Weekend passion project? Pick the membership with the schedule that best fits your work style.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">

                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M16.5 4.5c-1.61 0-3.492 1.825-4.5 3-1.008-1.175-2.89-3-4.5-3C4.651 4.5 3 6.722 3 9.55c0 3.133 3 6.45 9 9.95 6-3.5 9-6.75 9-9.75 0-2.828-1.651-5.25-4.5-5.25z" fill="#335EEA"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Wellness @ Work
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    The cornerstone of any community is its health and we are committed to our that of our members: meditation, mindfulness, yoga studio & gym discounts, etc.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">

                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4 4l7.631-1.43a2 2 0 01.738 0L20 4v9.283a8.51 8.51 0 01-4 7.217l-3.47 2.169a1 1 0 01-1.06 0L8 20.5a8.51 8.51 0 01-4-7.217V4z" fill="#335EEA" opacity=".3"/><path d="M11.175 14.75a.946.946 0 01-.67-.287l-1.917-1.917a.926.926 0 010-1.342c.383-.383 1.006-.383 1.341 0l1.246 1.246 3.163-3.162a.926.926 0 011.341 0 .926.926 0 010 1.341l-3.833 3.834a.946.946 0 01-.671.287z" fill="#335EEA"/></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Security
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Our doors are always locked and we even offer document shredding services for those private papers.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">

                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M11.26 5.814L2 16h20L12.74 5.814a1 1 0 00-1.48 0z" fill="#335EEA" opacity=".3"></path><path d="M12.006 15.712l8.275-8.86a1 1 0 011.73.684V20H2V7.536a1 1 0 011.73-.683l8.276 8.859z" fill="#335EEA"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Special Events & Meetups
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Bookable spaces for member-organized special events - both private & public. Did someone say Minecraft Meetup? Personal Finance Meetup?
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">


                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M14 7c-.333 3.333-1.333 5.117-3 5.35 0 0 1.5-5.85-.5-8.85 0 0-.212 3.214-2.355 7.072C7.14 12.38 6 13.599 6 16c0 3.429 3.511 5.2 6.006 5.2C14.5 21.2 18 20 18 15.8c0-1.727-1.333-4.66-4-8.8z" fill="#335EEA"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Mastermind Group
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Join us for 8-week facilitated mastermind groups that will help you break through challenges in both your personal and professional life. <Link to="/cotivation">Learn more &rarr;</Link>
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">


                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M13.686 18.708C15.91 17.82 18.683 17.25 22 17V5.506a.5.5 0 00-.513-.5C18.658 5.078 15.83 5.743 13 7v11.244a.5.5 0 00.686.464z" fill="#335EEA"></path><path d="M2.686 18.708C4.91 17.82 7.683 17.25 11 17V5.506a.5.5 0 00-.513-.5C7.658 5.078 4.83 5.743 2 7v11.244a.5.5 0 00.686.464z" fill="#335EEA" opacity=".3"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Members-Only Book Club
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Join us for both fiction and non-fiction books that our community loves (ahem, Atomic Habits?). We also have some author surprises!
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">


                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><circle fill="#335EEA" opacity=".3" cx="12" cy="12" r="10"></circle><path d="M12.42 17.158l3.037-6.073a.75.75 0 00-.67-1.085H12V7.177a.75.75 0 00-1.42-.335l-3.037 6.073A.75.75 0 008.213 14H11v2.823a.75.75 0 001.42.335z" fill="#335EEA"></path></g></svg>
                </div>


                <div class="ml-5">

                  <p class="font-size-lg font-weight-bold mb-1">
                    RC Member Perks
                  </p>

                  <p class="font-size-sm text-muted mb-0">
                    Being a literal collective has its perks! We have some amazing partnerships with local and online companies including Basecamp, <a href="https://thestudioreno.com" target="_blank">The Studio Reno</a>, <a href="https://moo.com" target="_blank">Moo</a>, <a href="https://stickermule.com" target="_blank">Stickermule</a>, and <a href="https://aws.amazon.com" target="_blank">AWS</a>.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">


                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><circle fill="#335EEA" opacity=".3" cx="12" cy="12" r="10"></circle><path d="M12 16a1 1 0 110 2 1 1 0 010-2zm-1.409-1.132v-1.659h1.26c1.596 0 2.751-1.218 2.751-2.814s-1.155-2.814-2.751-2.814c-1.617 0-2.73 1.218-2.73 2.814H7.336c0-2.52 1.974-4.473 4.515-4.473 2.541 0 4.536 1.953 4.536 4.473 0 2.52-1.995 4.473-4.536 4.473h-1.26z" fill="#335EEA"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Live Q&A / AMA Sessions
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Learn from, or share your own expertise in, our live, themed Q&A and AMA sessions featuring business review hot-seats, expert panels and pitch practice.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">


                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M4.05 17.485l-1.45-1.45a1 1 0 010-1.414l3.536-3.535L7.55 12.5a1 1 0 101.414-1.414L7.55 9.672 14.621 2.6a1 1 0 011.415 0l1.45 1.45a2.5 2.5 0 003.464 3.464l1.45 1.45a1 1 0 010 1.414l-7.072 7.07-1.414-1.413A1 1 0 0012.5 17.45l1.414 1.414-3.535 3.535a1 1 0 01-1.415 0l-1.45-1.45a2.5 2.5 0 00-3.464-3.464zm5.622-4.278a1 1 0 000 1.414l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 0z" fill="#335EEA" opacity=".3"></path></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Day Passes
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    In town for the day? We know how important it is to have a good space to work, come check us out.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">


                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M16.374 19.939l5.849-8.773A.75.75 0 0021.599 10H17V4.477a.75.75 0 00-1.374-.416l-5.849 8.773A.75.75 0 0010.401 14H15v5.523a.75.75 0 001.374.416z" fill="#335EEA"/><path d="M4.5 5h5a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3zm0 12h5a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3zm-2-6h4a1.5 1.5 0 010 3h-4a1.5 1.5 0 010-3z" fill="#335EEA" opacity=".3"/></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Connectivity
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    "Get Shit Done" faster and more securely with our reliable high-speed, fiber internet. Ethernet also available.
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">


                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M4.5 6h15c1.38 0 2.5.97 2.5 2.167v8.666C22 18.03 20.88 19 19.5 19h-15C3.12 19 2 18.03 2 16.833V8.167C2 6.97 3.12 6 4.5 6zM4 8v9h16V8H4z" fill="#335EEA"></path><path fill="#335EEA" opacity=".3" d="M4 8v9h16V8z"></path><rect fill="#335EEA" opacity=".3" x="7" y="20" width="10" height="1" rx=".5"></rect></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Need a Room?
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Bookable conference rooms are available for meetings, group calls, and video conferencing. Have lunch delivered!
                  </p>

                </div>

              </div>

            </div>
            <div class="col-md-6 col-lg-4">


              <div class="d-flex mt-8 mt-md-9">


                <div class="icon text-primary-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M12.998 17.93L13 18v3a1 1 0 01-2 0v-3c0-.024 0-.047.002-.07A7.002 7.002 0 015 11h2a5 5 0 0010 0h2a7.002 7.002 0 01-6.002 6.93z" fill="#335EEA"></path><rect fill="#335EEA" opacity=".3" x="9" y="2" width="6" height="12" rx="3"></rect></g></svg>
                </div>


                <div class="ml-5">


                  <p class="font-size-lg font-weight-bold mb-1">
                    Record the next big Podcast
                  </p>


                  <p class="font-size-sm text-muted mb-0">
                    Any true-crime fans? Turns out being in a former recording studio makes for great podcast and audio recording.
                  </p>

                </div>

              </div>

            </div>
          </div>
        </div>
      </section>

    <section>
      <div class="container-lg position-relative" style={{zIndex: 1}}>
        <div class="row no-gutters">
          <div class="col-md-6 bg-cover rounded-top-left-lg rounded-bottom-right-lg mb-6 mb-md-0 d-none d-md-block" style={{backgroundImage: "url(img/collective/residents.jpg)"}}>


            <img class="img-fluid invisible" src="img/collective/residents.jpg" alt="Resident office at Reno Collective" />

          </div>
          <div class="col-md-6 py-md-6">


            <div class="card h-100 rounded-bottom-right-lg border-top border-top-4 border-primary">
              <div class="card-body text-center d-flex flex-column py-10">


                <h6 class="text-uppercase text-primary mb-5">
                  Part of something bigger than yourself
                </h6>


                <p class="lead my-auto mb-6">
                  Trying to figure out how becoming a member will help you and your business?
                </p>

                <p className="text-muted my-auto mt-4">
                  Schedule a tour and come tell us more about your business
                </p>


                <div class="mt-6">
                  <a class="btn btn-primary lift" href="https://calendly.com/renocollective/rc-tour">
                    Schedule a tour
                  </a>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>


    <div class="position-relative">
      <div class="shape shape-fluid-x shape-top text-dark pb-15">
        <div class="shape-img pb-8 pb-md-11">
          <svg viewBox="0 0 100 50" preserveAspectRatio="none"><path d="M0 25h25L75 0h25v50H0z" fill="currentColor"/></svg>
        </div>
      </div>
    </div>
  </div>
  )
}

PerksPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PerksPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <PerksPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

PerksPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PerksPage

export const perksPageQuery = graphql`
  query perksPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
